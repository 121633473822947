// stupid simple formatter, we just ignore +1 and whatever else is at the beginning
export default function (number: any) {
  const num = sanitizePhoneNumber(number)

  // Do not format if its not in E164 format
  // +1.7013678810
  if (!num.includes('+1')) return number

  const areaCode = num.substr(-10, 3) // 701
  const prefix = num.substr(-7, 3) // 367
  const local = num.substr(-4) // 8810

  return `${areaCode}-${prefix}-${local}` // 701-367-8810
}
